<template>
  <div>
    <el-form ref="form" :model="criteria" label-width="80px">
      <el-row>
        <el-col :span="4"> 
          <el-form-item label="设备编号">
            <el-input v-model="criteria.accountType"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="区服">
            <el-input v-model="criteria.userServer"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="门派">
            <el-input v-model="criteria.userSect"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="金币">
            <el-input v-model="criteria.userGold"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
            <div style="font-size:16px;padding-left:10px;line-height:40px;">金币：{{ Gold }}</div>
        </el-col>
        <el-col :span="4">
          <div style="margin-left:10px"><el-button type="primary" @click="init()">查询</el-button></div>
        </el-col>
        
      </el-row>
    </el-form>
    <div class="min-table-h">
      <el-table :data="tableData" show-summary :summary-method="getSummaries">
        <el-table-column fixed prop="accountType" label="设备编号" width="80"></el-table-column>
        <el-table-column prop="user" label="账号" width="150"></el-table-column>
        <el-table-column prop="userServer" label="服务器" width="100"></el-table-column>
        <el-table-column prop="userName" label="角色名"></el-table-column>
        <el-table-column prop="userGrade" label="等级"></el-table-column>
        <el-table-column prop="userType" label="造型"></el-table-column>
        <el-table-column prop="userId" label="角色ID"></el-table-column>
        <el-table-column prop="userGold" label="金币"></el-table-column>
        <el-table-column prop="userGold1" label="冻结金币"></el-table-column>
        <el-table-column prop="silverCoin" label="银币"></el-table-column>
        <el-table-column prop="userSect" label="门派"></el-table-column>
        <el-table-column prop="userGold2" label="神兜兜" width="80"></el-table-column>
        <el-table-column prop="userGold3" label="宝石锤" width="80"></el-table-column>
        <el-table-column prop="huoYue" label="活跃度" width="80"></el-table-column>
        <el-table-column prop="userGl" label="隔离状态" width="80"></el-table-column>
        <el-table-column prop="runTime" label="运行时间(分)" width="110"></el-table-column>
        <el-table-column prop="userUpdateTime" label="更新时间" width="160"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button round @click="deleteid(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage1"
      :page-sizes="[10, 50, 100, 150]" :page-size="criteria.pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {
  getUserData,
  deleteScript,
  deleteid
} from "@/api/script";
export default {
  data() {
    return {
      query: this.$route.query,
      tableData: [],
      criteria: {
        accountType: "",
        userServer: "",
        userSect: "",
        userGold: "",
        user:this.$store.state.loginName.username
      },
      total: "",
      item: "",
      deviceData: "",
      Gold:0
    };
  },
  computed: {
    formdata() {
      return function (time) {
        return dayjs(time).format("YYYY-MM-DD");
      };
    },
     
  },
  watch: {
    $route(to) {
      this.query = to.query;
      this.init();
    },
  },
  mounted() {
    console.log(this.criteria)
    this.init();
  },
  methods: {
    init() {
      getUserData(this.criteria).then((res) => {
        console.log(res)
        if (res.status === 200) {
          res.data.map(item=>{
            item.userUpdateTime = this.formatTime(item.userUpdateTime)
          })
          this.tableData = res.data;
              
      }
      });
    },
    onDelete() {
      deleteScript({
        user: this.$store.state.loginName.username,
        accountType: this.deviceData.accountType,
        id: this.$route.query.id,
      }).then((res) => {
        if (res.status === 200) {
          this.$store.commit("deleteType", this.$route.query.id);

        }
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index !== 0 && column.property !== "userGold") return;
        if (index === 0) {
          sums[index] = "金币总数";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += "";
        } else {
          sums[index] = "N/A";
        }
      });
      this.Gold = sums[7]
      return sums;
    },
   // 定义格式化函数
   formatTime(time) {
    const timer = new Date(time)
    const year = timer.getFullYear()
    const mouth = timer.getMonth() + 1 >= 10 ? timer.getMonth() + 1 : '0' + (timer.getMonth() + 1)
    const date = timer.getDate() >= 10 ? timer.getDate() : '0' + timer.getDate()
    const hours = timer.getHours() >= 10 ? timer.getHours() : '0' + timer.getHours()
    const min = timer.getMinutes() >= 10 ? timer.getMinutes() : '0' + timer.getMinutes()
    const secon = timer.getSeconds() >= 10 ? timer.getSeconds() : '0' + timer.getSeconds()
    return year + '-' + mouth + '-' + date + ' ' + hours + ':' + min + ':' + secon
  },
  deleteid(item) {
      deleteid({
        id: item.id
      }).then((res) => {
        if (res.status === 200) {
          this.tableData = this.tableData.filter(e => e.id !== item.id)
        }
      });
    }
  },
};
</script>

<style></style>